<template>
  <div style="width: 100%;">
    <v-container fluid>
      <v-row class="hideOnPagePrint">
        <v-col class="d-flex flex-row align-center">
          <v-btn @click="$router.go(-1)" text><v-icon>mdi-arrow-left</v-icon></v-btn>
          <h1>Admin Product History</h1>
          <v-btn v-if="data && data.length>0" @click="printData" class="ml-2" fab color="info" x-small><v-icon>mdi-printer</v-icon></v-btn>
        </v-col>
      </v-row>
      <v-row class="hideOnPagePrint">
        <v-col>
          <h3>Filters</h3>
          <span class="d-flex flex-row">
            <v-menu
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="filters.startDate"
                    label="Start Date"
                    readonly
                    clearable
                    outlined
                    v-bind="attrs"
                    v-on="on"
                />
              </template>
              <v-date-picker
                  v-model="filters.startDate"
              />
            </v-menu>
            <v-btn class="mt-2 mx-2" color="warning" @click="filters.endDate=filters.startDate"><v-icon>mdi-content-copy</v-icon><v-icon>mdi-arrow-right</v-icon></v-btn>
            <v-menu
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="filters.endDate"
                    label="End Date"
                    readonly
                    clearable
                    outlined
                    v-bind="attrs"
                    v-on="on"
                />
              </template>
              <v-date-picker
                  v-model="filters.endDate"
              />
            </v-menu>
            <v-spacer/>
            <v-spacer/>
          </span>
          <span style="margin-top: -20px;" class="d-flex flex-row mb-5">
            <v-btn class="mr-2" color="error" @click="filters.startDate=null;filters.endDate=null;">Clear Dates</v-btn>
            <v-btn class="mr-2" color="info" @click="dailyRecords">Today</v-btn>
            <v-btn class="mr-2" color="info" @click="monthlyRecords">This Month</v-btn>
            <v-btn class="mr-2" color="info" @click="yearlyRecords">This Year</v-btn>
          </span>

          <span class="d-flex flex-row">
            <v-autocomplete
                class="mx-1"
                :disabled="filters.excludedUserIds.length>0"
                v-model="filters.userIds"
                :items="getUserCache"
                :item-text="item=>(item.firstName||'')+' '+(item.lastName||'')"
                item-value="id"
                outlined
                multiple
                label="Include Users"
                clearable
            ></v-autocomplete>
            <v-btn :disabled="!filters.excludedUserIds && !filters.userIds" class="mt-2" color="warning" @click="swapUserFields"><v-icon>mdi-swap-horizontal</v-icon></v-btn>
            <v-autocomplete
                class="mx-1"
                :disabled="filters.userIds.length>0"
                v-model="filters.excludedUserIds"
                :items="getUserCache"
                multiple
                :item-text="item=>(item.firstName||'')+' '+(item.lastName||'')"
                item-value="id"
                outlined
                label="Exclude Users"
                clearable
            ></v-autocomplete>
          </span>

          <span class="d-flex flex-row mb-5">
            <v-btn v-if="!filters.product" color="info" @click="openFindProductDialog">Select Product <v-icon>mdi-magnify</v-icon></v-btn>
            <v-select v-else clearable @click:clear="filters.product=null;filters.productId=null;" label="Product" outlined :items="[filters.product]" item-value="id" :item-text="x => `ID: ${x.id} | ${x.Brand?'['+x.Brand.name+']':''} ${x.name} } | ${x.sku} | ${utils.formatCurrency(x.regularPrice)}`" v-model="filters.product" append-outer-icon="mdi-magnify" @click:append-outer="openFindProductDialog"/>
          </span>

          <span class="d-flex flex-row">
            <v-autocomplete
                class="mx-1"
                v-model="filters.controller"
                :items="controllers"
                outlined
                multiple
                label="Controllers"
                clearable
            ></v-autocomplete>
            <v-autocomplete
                class="mx-1"
                v-model="filters.focus"
                :items="focuses"
                multiple
                outlined
                label="Focus"
                clearable
            ></v-autocomplete>
            <v-autocomplete
                class="mx-1"
                v-model="filters.action"
                :items="actions"
                multiple
                outlined
                label="Actions"
                clearable
            ></v-autocomplete>
          </span>
          <span>
            <v-switch label="Expand all (takes slightly longer to load)" v-model="filters.allVisible" @change="closeRecords"/>
          </span>
          <span>
            <v-btn :loading="loader" class="mt-2 ml-2" color="success" @click="fetchRecords"><v-icon>mdi-magnify</v-icon> Search</v-btn>
          </span>
        </v-col>
      </v-row>
      <v-row v-if="data && data.length===0">
        <v-col>
          <span class="red--text">No records found with the provided filters.</span>
        </v-col>
      </v-row>
      <v-row v-if="data">
        <v-col>
          <span class="d-flex flex-column">
            <span>{{`Showing ${data.length} records for the chosen filters.`}}</span>
            <span>{{`Filters:`}}</span>
            <pre>{{JSON.stringify({...filters, product: "hidden from view"}, null, 2)}}</pre>
          </span>
          <v-card class="mb-2" outlined v-for="(record, i) of data" :key="i">
            <v-card-title class="justify-space-between" @click="record.visible=!record.visible" style="cursor: pointer;">
              <span>
                <v-chip class="mr-2" color="warning">{{record.controller}}</v-chip>
                <v-chip class="mr-2" color="light-purple">{{record.focus}}</v-chip>
                <v-chip class="mr-2" color="cyan" dark>{{record.action}}</v-chip>
                <span class="mr-2">{{record.productId}} | {{record.product.Brand?'['+record.product.Brand.name+']':''}} {{record.product.name}} | {{record.product.sku}}</span>
              </span>
              <span>
                <span class="px-2" style="font-size: 14px; font-style: normal;">{{lookupUsername(record.userId)}}</span>
                <span>{{utils.formatDate(record.createdAt, "withTime")}}</span>
                <v-btn fab text small class="ml-2" @click="record.visible=!record.visible"><v-icon>{{record.visible?'mdi-menu-up':'mdi-menu-down'}}</v-icon></v-btn>
              </span>
            </v-card-title>
            <v-card-text v-if="record.visible">
              <v-card outlined class="mb-5 pa-5">
                <h2>Product</h2>
                <span>
                  <span class="mx-1 my-1" v-for="(objKey, j) of Object.keys(record.product).sort()" :key="j">
                    <span v-if="objKey==='Brand' && record.product[objKey]">
                      <v-chip class="my-1"><b>{{objKey}}:</b></v-chip>
                      <span v-for="(objKey2, k) of Object.keys(record.product[objKey])" :key="k">
                        <v-chip small color="secondary"><b class="my-1 mr-1">{{objKey2}}:</b>{{record.product[objKey][objKey2]}}</v-chip>
                      </span>
                    </span>
                    <v-chip v-else class="my-1"><b class="mr-1">{{objKey}}:</b>{{record.product[objKey]}}</v-chip>
                    <br v-if="['Brand'].includes(objKey) && record.product[objKey]">
                  </span>
                </span>
                <span class="d-flex flex-row">
                  <v-btn color="info" small @click="showRaw.product=!showRaw.product">{{showRaw.product?'Hide':'Show'}} Raw</v-btn>
                </span>
                <span v-if="showRaw.product">
                  <br>
                  <pre>{{JSON.stringify(record.product, null, "\t")}}</pre>
                </span>
              </v-card>
              <v-card outlined class="mb-5 pa-5">
                <h2>Locations</h2>
                <span>
                  <v-card outlined class="pa-2 mx-1 mb-2" v-for="(obj, k) of record.locations" :key="k">
                    <span class="mx-1 my-1" v-for="(objKey, j) of Object.keys(obj).sort()" :key="j">
                      <span v-if="objKey==='Location' && obj[objKey]">
                        <v-chip class="my-1"><b>{{objKey}}:</b></v-chip>
                        <span v-for="(objKey2, k) of Object.keys(obj[objKey])" :key="k">
                          <v-chip small color="secondary"><b class="my-1 mr-1">{{objKey2}}:</b>{{obj[objKey][objKey2]}}</v-chip>
                        </span>
                      </span>
                      <v-chip v-else class="my-1"><b class="mr-1">{{objKey}}:</b>{{obj[objKey]}}</v-chip>
                      <br v-if="['Location'].includes(objKey) && obj[objKey]">
                    </span>
                  </v-card>
                </span>
                <span class="d-flex flex-row">
                  <v-btn color="info" small @click="showRaw.locations=!showRaw.locations">{{showRaw.locations?'Hide':'Show'}} Raw</v-btn>
                </span>
                <span v-if="showRaw.locations">
                  <br>
                  <pre>{{JSON.stringify(record.locations, null, "\t")}}</pre>
                </span>
              </v-card>
              <v-card outlined class="mb-5 pa-5">
                <h2>Supplier</h2>
                <span>
                  <v-card outlined class="pa-2 mx-1 mb-2" v-for="(obj, k) of record.suppliers" :key="k">
                    <span class="mx-1 my-1" v-for="(objKey, j) of Object.keys(obj).sort()" :key="j">
                      <span v-if="objKey==='Supplier' && obj[objKey]">
                        <v-chip class="my-1"><b>{{objKey}}:</b></v-chip>
                        <span v-for="(objKey2, k) of Object.keys(obj[objKey])" :key="k">
                          <v-chip small color="secondary"><b class="my-1 mr-1">{{objKey2}}:</b>{{obj[objKey][objKey2]}}</v-chip>
                        </span>
                      </span>
                      <v-chip v-else class="my-1"><b class="mr-1">{{objKey}}:</b>{{obj[objKey]}}</v-chip>
                      <br v-if="['Supplier'].includes(objKey) && obj[objKey]">
                    </span>
                  </v-card>
                </span>
                <span class="d-flex flex-row">
                  <v-btn color="info" small @click="showRaw.suppliers=!showRaw.suppliers">{{showRaw.suppliers?'Hide':'Show'}} Raw</v-btn>
                </span>
                <span v-if="showRaw.suppliers">
                  <br>
                  <pre>{{JSON.stringify(record.suppliers, null, "\t")}}</pre>
                </span>
              </v-card>
              <v-card outlined class="mb-5 pa-5">
                <h2>Categories</h2>
                <span>
                  <v-card outlined class="pa-2 mx-1 mb-2" v-for="(obj, k) of record.categories" :key="k">
                    <span class="mx-1 my-1" v-for="(objKey, j) of Object.keys(obj).sort()" :key="j">
                      <span v-if="objKey==='ProductCategory' && obj[objKey]">
                        <v-chip class="my-1"><b>{{objKey}}:</b></v-chip>
                        <span v-for="(objKey2, k) of Object.keys(obj[objKey])" :key="k">
                          <v-chip small color="secondary"><b class="my-1 mr-1">{{objKey2}}:</b>{{obj[objKey][objKey2]}}</v-chip>
                        </span>
                      </span>
                      <v-chip v-else class="my-1"><b class="mr-1">{{objKey}}:</b>{{obj[objKey]}}</v-chip>
                      <br v-if="['ProductCategory'].includes(objKey) && obj[objKey]">
                    </span>
                  </v-card>
                </span>
                <span class="d-flex flex-row">
                  <v-btn color="info" small @click="showRaw.categories=!showRaw.categories">{{showRaw.categories?'Hide':'Show'}} Raw</v-btn>
                </span>
                <span v-if="showRaw.categories">
                  <br>
                  <pre>{{JSON.stringify(record.categories, null, "\t")}}</pre>
                </span>
              </v-card>
              <v-card outlined class="mb-5 pa-5">
                <h2>Tags</h2>
                <span>
                  <v-card outlined class="pa-2 mx-1 mb-2" v-for="(obj, k) of record.tags" :key="k">
                    <span class="mx-1 my-1" v-for="(objKey, j) of Object.keys(obj).sort()" :key="j">
                      <span v-if="objKey==='ProductTag' && obj[objKey]">
                        <v-chip class="my-1"><b>{{objKey}}:</b></v-chip>
                        <span v-for="(objKey2, k) of Object.keys(obj[objKey])" :key="k">
                          <v-chip small color="secondary"><b class="my-1 mr-1">{{objKey2}}:</b>{{obj[objKey][objKey2]}}</v-chip>
                        </span>
                      </span>
                      <v-chip v-else class="my-1"><b class="mr-1">{{objKey}}:</b>{{obj[objKey]}}</v-chip>
                      <br v-if="['ProductTag'].includes(objKey) && obj[objKey]">
                    </span>
                  </v-card>
                </span>
                <span class="d-flex flex-row">
                  <v-btn color="info" small @click="showRaw.tags=!showRaw.tags">{{showRaw.tags?'Hide':'Show'}} Raw</v-btn>
                </span>
                <span v-if="showRaw.tags">
                  <br>
                  <pre>{{JSON.stringify(record.tags, null, "\t")}}</pre>
                </span>
              </v-card>
              <v-card outlined class="mb-5 pa-5">
                <h2>Attributes</h2>
                <span>
                  <v-card outlined class="pa-2 mx-1 mb-2" v-for="(obj, k) of record.attributes" :key="k">
                    <span class="mx-1 my-1" v-for="(objKey, j) of Object.keys(obj).sort()" :key="j">
                      <span v-if="objKey==='ProductAttributeJoin' && obj[objKey]">
                        <v-chip class="my-1"><b>{{objKey}}:</b></v-chip>
                        <span v-for="(objKey2, k) of Object.keys(obj[objKey])" :key="k">
                          <br v-if="objKey2==='ProductAttribute' && obj[objKey][objKey2]">
                          <span v-if="objKey2==='ProductAttribute' && obj[objKey][objKey2]" class="ml-10">
                            <v-chip color="secondary" class="my-1"><b>{{objKey2}}:</b></v-chip>
                            <span v-for="(objKey3, l) of Object.keys(obj[objKey][objKey2])" :key="l">
                              <v-chip small color="teal" dark class="my-1"><b class="mr-1">{{objKey3}}:</b>{{obj[objKey][objKey2][objKey3]}}</v-chip>
                            </span>
                          </span>
                          <v-chip v-else small color="secondary"><b class="my-1 mr-1">{{objKey2}}:</b>{{obj[objKey][objKey2]}}</v-chip>
                        </span>
                      </span>
                      <span v-else-if="objKey==='ProductAttributeTerm' && obj[objKey]">
                        <v-chip class="my-1"><b class="mr-1">{{objKey}}:</b></v-chip>
                        <span v-for="(objKey2, k) of Object.keys(obj[objKey])" :key="k">
                          <v-chip small color="secondary" class="my-1"><b class="mr-1">{{objKey2}}:</b>{{obj[objKey][objKey2]}}</v-chip>
                        </span>
                      </span>
                      <v-chip v-else class="my-1"><b class="mr-1">{{objKey}}:</b>{{obj[objKey]}}</v-chip>
                      <br v-if="['ProductAttributeJoin', 'ProductAttributeTerm'].includes(objKey) && obj[objKey]">
                    </span>
                  </v-card>
                </span>
                <span class="d-flex flex-row">
                  <v-btn color="info" small @click="showRaw.attributes=!showRaw.attributes">{{showRaw.attributes?'Hide':'Show'}} Raw</v-btn>
                </span>
                <span v-if="showRaw.attributes">
                  <br>
                  <pre>{{JSON.stringify(record.attributes, null, "\t")}}</pre>
                </span>
              </v-card>
              <v-card outlined class="mb-5 pa-5">
                <h2>Metadata</h2>
                <span>
                  <v-chip class="mx-1 my-1" v-for="(objKey, j) of Object.keys(record.metadata).sort()" :key="j"><b class="mr-1">{{objKey}}:</b>{{record.metadata[objKey]}}</v-chip>
                </span>
                <span class="d-flex flex-row">
                  <v-btn color="info" small @click="showRaw.metadata=!showRaw.metadata">{{showRaw.metadata?'Hide':'Show'}} Raw</v-btn>
                </span>
                <span v-if="showRaw.metadata">
                  <br>
                  <pre>{{JSON.stringify(record.metadata, null, "\t")}}</pre>
                </span>
              </v-card>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog scrollable v-model="findProductDialog.isOpen" width="800">
      <v-card>
        <v-card-title>Lookup Product</v-card-title>
        <v-card-text>
          <span class="d-flex flex-row justify-space-between pt-2">
            <v-text-field outlined v-model="findProductDialog.search" label="Search" @change="findProduct"/>
            <v-btn class="mt-2 ml-2" fab small color="info" @click="findProduct" :disabled="!findProductDialog.search"><v-icon>mdi-magnify</v-icon></v-btn>
          </span>
          <div style="max-height: 500px; overflow-y: scroll">
            <v-card style="cursor: pointer;" v-for="(product, i) of findProductDialog.results" :key="i" outlined class="mb-1 pa-1" @click="selectProduct(product)">
              <span class="ml-2">ID:{{product.id}} | </span>
              <span class="ml-2" style="font-size: 18px;"><b>{{product.Brand?'['+product.Brand.name+']':''}} {{product.name}}</b></span>
              <span v-if="product.sku"> | {{product.sku}}</span>
              <span style="float: right;">{{utils.formatCurrency(product.regularPrice)}}</span>
            </v-card>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn class="error" @click="closeFindProductDialog" text>Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackObj.state" :timeout="3000" :color="snackObj.color">
      {{ snackObj.text }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snackObj.state = false">Close</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<style scoped>
  table{
    border-collapse: collapse;
  }
  tr{
    border-bottom: 1px solid #ccc;
  }
</style>
<script>
  import axios from 'axios';
  import moment from 'moment'
  import {mapGetters} from "vuex";
  import utils from "../../plugins/helpers"
  export default {
    data () {
      return {
        utils: utils,
        deleteDialog: false,
        deleteConfirmed: true,
        loader: false,
        snackObj: {
          state: false,
          color: '',
          text: ''
        },

        filters: {
          startDate: null,
          endDate: null,
          userIds: [],
          excludedUserIds: [],
          controller: [],
          action: [],
          focus: [],
          product: null,
          productId: null,
          allVisible: false
        },

        findProductDialog: {
          isOpen: false,
          search: "",
          loading: false,
          results: []
        },

        showRaw: {
          product: false,
          locations: false,
          suppliers: false,
          categories: false,
          tags: false,
          attributes: false,
          metadata: false
        },

        controllers: [],
        actions: [],
        focuses: [],

        data: null,
      }
    },
    async mounted(){
      await this.getAllData();
    },
    computed: {
      ...mapGetters(['getEndpoint', "isAllowed", 'lookupUsername', 'getUserCache', 'getGlobalValue', 'paymentTypes']),
    },
    methods: {
      snack(text, color=""){
        this.snackObj.text = text;
        this.snackObj.state = true;
        this.snackObj.color = color;
      },
      async getAllData(){
        try {
          this.loader = true;

          let res = await axios.get(`${this.getEndpoint}/api/productHistory/metadata`)
          if(res.data.error) throw res.data.error

          this.controllers = res.data.data.controllers;
          this.focuses = res.data.data.focuses;
          this.actions = res.data.data.actions;

          let now = new Date();
          let firstDay = new Date(now.getFullYear(), now.getMonth(), 1);
          let lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);
          let userIds = [];
          let excludedUserIds = [];
          let controllers = [];
          let actions = []
          let focuses = []
          let visible = false;

          if(this.$route.query.startDate){
            firstDay = new Date(this.$route.query.startDate);
          }
          if(this.$route.query.endDate){
            lastDay = new Date(this.$route.query.endDate);
          }
          if(this.$route.query.userIds){
            userIds = Array.isArray(this.$route.query.userIds)?(this.$route.query.userIds.map(x => parseInt(x))):[parseInt(this.$route.query.userIds)];
          }
          if(this.$route.query.excludedUserIds){
            if(userIds.length===0){
              excludedUserIds = Array.isArray(this.$route.query.excludedUserIds)?(this.$route.query.excludedUserIds.map(x => parseInt(x))):[parseInt(this.$route.query.excludedUserIds)];
            }
          }

          this.filters.product = null;
          this.filters.productId = null;

          if(this.$route.query.productId){
            this.filters.productId = parseInt(this.$route.query.productId)

            let searchData = {val: `pl${this.filters.productId}`}
            let uriFields = Object.keys(searchData).map(x => {
              return x + "=" + (searchData[x]!==undefined?encodeURIComponent(searchData[x]):'')
            }).join("&");

            let res = await axios.get(`${this.getEndpoint}/api/products/searchByAllVariationsNoLimit?${uriFields}`);
            if(res.data.error) throw res.data.error
            this.filters.product = res.data.data.length>0?res.data.data[0]:null;
          }
          if(this.$route.query.allVisible){
            visible = this.$route.query.allVisible==='true'
          }
          if(this.$route.query.controller){
            controllers = Array.isArray(this.$route.query.controller)?(this.$route.query.controller):[this.$route.query.controller];
          }
          if(this.$route.query.action){
            actions = Array.isArray(this.$route.query.action)?(this.$route.query.action):[this.$route.query.action];
          }
          if(this.$route.query.focus){
            focuses = Array.isArray(this.$route.query.focus)?(this.$route.query.focus):[this.$route.query.focus];
          }

          this.filters.startDate = this.utils.parseDate(firstDay);
          this.filters.endDate = this.utils.parseDate(lastDay);
          this.filters.userIds = userIds
          this.filters.excludedUserIds = excludedUserIds
          this.filters.controller = controllers
          this.filters.action = actions
          this.filters.focus = focuses
          this.filters.allVisible = visible

          this.$forceUpdate();
        } catch (error) {
          console.error(error);
          this.snack(error.msg || error.msg?.message || error, "error");
        } finally {
          this.loader = false;
        }
      },
      async fetchRecords(){
        try {
          this.loader = true;
          this.data = null;
          this.totals = null;
          this.userData = null;

          let res = await axios.post(`${this.getEndpoint}/api/productHistory`, this.filters)
          if(res.data.error) throw res.data.error

          let changed = false;
          let tempObj = {...this.filters}
          delete tempObj.product

          if(Object.keys(this.$route.query).length!==Object.keys(tempObj).length) changed = true;
          if(!changed){
            for(let key of Object.keys(this.$route.query)){
              if(tempObj[key]!=this.$route.query[key] && !Array.isArray(tempObj[key])){
                if((this.$route.query[key]==='true' && tempObj[key]) || (this.$route.query[key]==='false' && !tempObj[key])) continue;
                changed = true;
                break;
              }
              if(Array.isArray(tempObj[key])){
                if(Object.keys(this.$route.query[key]).length!==Object.keys(tempObj[key]).length){
                  changed = true;
                  break;
                }
                for(let i=0;i<tempObj[key].length;i++){
                  if(tempObj[key][i]!=this.$route.query[key][i]){
                    changed = true;
                    break;
                  }
                }
              }
            }
          }

          if(changed) await this.$router.replace({query: {...tempObj}});
          if(res.data.data.length===0) throw "No records found within the provided filters."

          this.data = res.data.data;

        } catch (error) {
          console.error(error);
          this.snack(error.msg || error.msg?.message || error, "error");
        } finally {
          this.loader = false;
        }
      },

      async dailyRecords(){
        this.filters.startDate = moment().format("YYYY-MM-DD");
        this.filters.endDate = moment().format("YYYY-MM-DD");
      },
      async monthlyRecords(){
        this.filters.startDate = moment().startOf('month').format("YYYY-MM-DD");
        this.filters.endDate = moment().endOf('month').format("YYYY-MM-DD");
      },
      async yearlyRecords(){
        this.filters.startDate = moment().startOf('year').format("YYYY-MM-DD");
        this.filters.endDate = moment().endOf('year').format("YYYY-MM-DD");
      },

      async printData(){
        let els = document.querySelectorAll(".hideOnPagePrint");
        let defaults = [];
        for(let el of els){
          defaults.push(el.style.display);
          el.style.setProperty("display", "none", "important");
        }

        let els2 = document.querySelectorAll(".adjustForPagePrint");
        let defaults2 = [];
        // let defaults3 = [];
        for(let el of els2){
          defaults2.push(el.style.padding);
          // defaults3.push(el.style.paddingRight);
          el.style.setProperty("padding", "0px 50px 0px 0px");
          // el.style.setProperty("paddingRight", "20px");
        }

        window.print();

        for(let i=0;i<els.length;i++){
          els[i].style.setProperty("display", defaults[i], "");
        }

        for(let i=0;i<els2.length;i++){
          els2[i].style.setProperty("padding", defaults2[i], "");
        }
      },
      async swapUserFields(){
        if(this.filters.userIds.length>0){
          let temp = this.filters.userIds;
          this.filters.userIds = [];
          this.filters.excludedUserIds = temp;
        }
        else if(this.filters.excludedUserIds.length>0){
          let temp = this.filters.excludedUserIds;
          this.filters.excludedUserIds = [];
          this.filters.userIds = temp;
        }
      },

      openFindProductDialog(){
        this.findProductDialog.isOpen = true;
      },
      closeFindProductDialog(){
        this.findProductDialog = {
          loading: false,
          results: [],
          search: "",
          isOpen: false
        }
      },
      async findProduct(){
        try{
          if(!this.findProductDialog.search) return

          let searchData = {val: this.findProductDialog.search}
          let uriFields = Object.keys(searchData).map(x => {
            return x + "=" + (searchData[x]!==undefined?encodeURIComponent(searchData[x]):'')
          }).join("&");

          let res = await axios.get(`${this.getEndpoint}/api/products/searchByAllVariationsNoLimit?${uriFields}`);
          if(res.data.error) throw res.data.error
          this.findProductDialog.results = res.data.data;

        } catch (error) {
          console.error(error);
          this.snack(error.msg || error.msg?.message || error, "error");
        } finally {
          this.findProductDialog.loading = false;
        }
      },
      selectProduct(product){
        this.filters.product = product;
        this.filters.productId = product.id;
        this.closeFindProductDialog();
      },
      closeRecords(){
        for(let rec of this.data){
          rec.visible = this.filters.allVisible
        }
      }
    }
  }
</script>
